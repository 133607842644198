<template>
  <div class="page">
    <div class="main-title">基本信息</div>
    <div class="classItem">
      <div class="classItem-top">
        <div class="name">{{ detail.className }}</div>
        <div class="time">{{ detail.time }}</div>
      </div>
      <div class="place">地点：{{ detail.classAddress }}</div>
      <div class="time">预约日期：{{ detail.appointmentDate }}</div>
    </div>
    <div class="main-title">签到</div>
    <img
      v-if="detail.signType === 0"
      src="./img/signIn.png"
      alt=""
      class="optBtn"
      @click="signIn()"
    />
    <img
      v-else-if="detail.signType === 1"
      src="./img/signedIn.png"
      alt=""
      class="optBtn"
    />
    <img
      v-else-if="detail.signType === 2"
      src="./img/outOfScope.png"
      alt=""
      class="optBtn"
    />
  </div>
</template>

<script>
import { myAppointmentDetails, classSignIn } from "./api.js";
export default {
  data() {
    return {
      detail: {},
      classId: null,
      lat: null,
      lng: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    const { classId } = this.$route.query;
    this.classId = classId;
  },
  mounted() {
    this.getDetail();
    this.lat = localStorage.getItem("latitude");
    this.lng = localStorage.getItem("longitude");
  },
  methods: {
    signIn() {
      let params = {
        classId: this.classId,
        userId: this.userId,
        signType: 1,
      };
      this.$axios.post(`${classSignIn}`, params).then((res) => {
        if (res.code == 200) {
          this.getDetail();
        }
      });
    },
    getDetail() {
      let params = {
        classId: this.classId,
        userId: this.userId,
        lat: localStorage.getItem("latitude"),
        lng: localStorage.getItem("longitude"),
      };
      this.$axios
        .get(`${myAppointmentDetails}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 90px;
  .optBtn {
    width: 252px;
    height: 252px;
    margin: 86px auto 0;
    display: block;
  }
  .main-title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
  .classItem {
    height: 252px;
    background: #f3f8fc;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 0 34px;
    margin: 38px 0 72px;
    .place {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 20px;
      padding-top: 18px;
    }
    .time {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .classItem-top {
      height: 96px;
      box-sizing: border-box;
      border-bottom: 2px solid #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>